<script setup>
import logo from "./assets/ppr-logo.png";
</script>
<template>
  <v-app id="inspire">
    <v-app-bar app>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        ><img :src="logo" width="25" />ParkProReakt Versorger
        Dashboard</v-toolbar-title
      >
      <div class="right">
        <!-- Hier Dateipfad für Installationsanleitung anpassen -->
        <a href="InstallationHilfestellung_250110.pdf" download class="mr-3" 
          ><v-icon>mdi-file-document</v-icon>Installationsanleitung App</a
        >
        <a
          href="https://parkpro.parkinson-marburg.de/meldung-von-technischen-problemen"
          target="_blank"
          rel="noopener noreferrer"
          class="mr-3"
          >Meldung von technischen Problemen</a
        >
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" location="left" temporary>
      <v-list>
        <v-list-item
          @click="
            $router.push({ name: 'patient-list', params: { active: true } })
          "
          title="Patientenliste"
        ></v-list-item>
        <v-list-item
          v-if="helpers.checkForRole(keycloak.token, 'coordinator')"
          @click="
            $router.push({ name: 'patient-list', params: { active: false } })
          "
          title="
          Patientenliste (Kontrolle)"
        ></v-list-item>
        <v-list-item
          @click="
            $router.push({ name: 'patient-list', params: { active: 'deleted' } })
          "
          title="
          Deaktivierte Patienten"
        ></v-list-item>
        <v-list-item
          v-if="helpers.checkForRole(keycloak.token, 'coordinator')"
          @click="
            $router.push({ name: 'data-export' })
          "
          title="
          Datenexport"
        ></v-list-item>
        <v-list-item @click="logout()" title="Logout"></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid outlined class="pt-0 pl-0" width="w-75">
        <v-row no-gutters="">
          <v-layout>
            <v-container>
              <v-card>
                <router-view></router-view>
              </v-card>
            </v-container>
          </v-layout>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
// import HelloWorld from './components/HelloWorld.vue'
import VueJWTDecode from "vue-jwt-decode";
import helpers from "./components/helpers/helpers";

// import axios from axios

export default {
  name: "App",

  components: {},

  data: () => ({
    drawer: false,
    authenticated: false,
    user_name: "",
    first_name: "",
    last_name: "",
    api_token: "",
  }),

  mounted() {
    this.authenticated = this.keycloak.authenticated;
    this.token = this.keycloak.token;
    const decoded_token = VueJWTDecode.decode(this.keycloak.token);
    this.first_name = decoded_token.given_name;
    this.last_name = decoded_token.family_name;
    console.log(this.keycloak);
    this.$router.push({ name: "patient-list", params: { active: true } });
  },

  methods: {
    logout: function () {
      this.keycloak.logout();
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
