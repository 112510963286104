<template>
  <v-progress-circular
    v-if="loading"
    indeterminate
    color="primary"
  ></v-progress-circular>
  <div v-if="symptomsList.length > 0">
    <v-row
      v-for="(data, key, index) in symptomsListDisplay"
      :key="index"
      class="symptoms-column"
    >
      <div>
        <span style="font-size: 1em">
          {{ makeString(key, data) }}
        </span>
      </div>
    </v-row>
    <v-row v-if="moreSymptoms">
      <strong style="font-size: 0.8em"
        >Achtung, es wurden weitere Symptome gemeldet!</strong
      >
    </v-row>
  </div>
  <div v-if="symptomNum === 0">
    Keine Symptom-Meldungen (seit mind. 3 Tagen)
  </div>
</template>

<style>
.custom-avatar {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* Makes the avatar circular */
  overflow: hidden;
  /* Ensures content doesn't overflow */
}

.centered-btn {
  display: flex;
  align-items: center;
  /* Vertically center content */
  /* Optional: Add padding or margin as needed */
}

.centered-text {
  margin-left: 8px;
  align-items: center;
  /* Adjust spacing between avatar and text as needed */
  /* Optional: Add additional styles to the text */
}

.symptoms-column {
  min-width: 300px;
  white-space: nowrap;
}
</style>

<script>
import helpers from "../helpers/helpers";

export default {
  name: "SymptomsTable",

  props: {
    symptomsList: [],
  },

  data: () => ({
    toggle_multiple: [],
    moreSymptoms: false,
    symptomNum: 0,
    mapping: {
      fall: { code: "1912002", color: "yellow-accent-2", display: "Sturz" },
      sleep: {
        code: "39898005",
        color: "light-blue-accent-1",
        display: "Schlafstörung",
      },
      digestion: {
        code: "300390002",
        color: "light-green",
        display: "Verdauungsstörung",
      },
      mobility: {
        code: "82971005",
        color: "blue-darken-4",
        display: "Mobilitätsstörungen",
      },
      tremor: { code: "26079004", color: "grey", display: "Tremor" },
      hospital: {
        code: "32485007",
        color: "purple",
        display: "Hospitalisierung",
      },
      acute: { code: "49634009", color: "pink", display: "akute Beschwerden" },
    },
    showAll: false,
  }),

  methods: {
    JSONStringToObject: function (json_str) {
      // if first and last chars ain't curly braces (which would indicate a json string), adjust the string
      // so that it becomes a json string
      const regex = /^{.*}$/;
      if (regex.test(json_str)) {
        return JSON.parse(json_str);
      } else {
        let str = '{"Grund": ';
        return JSON.parse(str.concat('"', json_str, '"}'));
      }
    },

    checkIfDate: function (test_var) {
      return isNaN(Date.parse(test_var)) ? false : true;
    },

    checkIfArray: function (test_var) {
      return Array.isArray(test_var);
    },

    convertToGermanDate: function (date) {
      return date
        .toLocaleTimeString("de", { timeZone: "Europe/Berlin" })
        .slice(0, 5);
    },

    makeString: function (date, data) {
      let str = helpers.formatDateStringToGermanDate(date) + ": ";
      for (let i = 0; i < data.length; i++) {
        str =
          str +
          Object.values(this.mapping).find(
            (item) => item.code == data[i].code.coding[0].code
          ).display;
        if (i !== data.length - 1) {
          str = str + ", ";
        }
      }
      return str;
    },
  },

  computed: {
    symptomsListDisplay: function () {
      if (this.symptomsList.length > 0) {
        const groupedData = this.symptomsList.reduce((acc, item) => {
          // Extract the date portion without the time
          console.log(item);
          const dateWithoutTime = item.resource.effectiveDateTime.split("T")[0];

          // Check if the date exists in the accumulator
          if (!acc[dateWithoutTime]) {
            acc[dateWithoutTime] = [];
          }

          // Push the item to the corresponding date
          acc[dateWithoutTime].push(item.resource);

          return acc;
        }, {});

        return groupedData;
      } else {
        return this.symptomsList;
      }
    },
  },

  mounted() {
    this.symptomNum = this.symptomsList.length;
    if (this.symptomsList.length > 3) {
      this.moreSymptoms = true;
    }
  },
};
</script>
